<template>
    <div 
        v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])"
        class="uk-container uk-container-expand uk-margin-top card-scrollable"
    >
        <div class="uk-flex uk-flex-between uk-flex-middle">
            <p class="uk-text-large">Create New Vacancy</p>
            <router-link to="/admin/marketing/silver_package" uk-close></router-link>
        </div>

        <div class="uk-margin-top">
            <form @submit.prevent="createSilver" class="uk-grid-small" uk-grid>
                <div class="uk-width-1-1">
                    <div class="uk-grid-small" uk-grid>
                        <section class="uk-width-1-2@s">
                            <label for="title" class="uk-form-label">Vacancy Title<span class="mandatory">*</span></label>
                            <input
                                id="title" 
                                name="Vacancy Title"
                                class="uk-input input-text" 
                                type="text" 
                                placeholder="Type vacancy title..."
                                v-model="form.title"
                                v-validate="'required'"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Vacancy Title')">{{ errors.first('Vacancy Title') }}</span>
                        </section>
                        <section class="uk-width-1-2@s">
                            <label for="job_type" class="uk-form-label">Job Type<span class="mandatory">*</span></label>
                            <div>
                                <multiselect
                                    id="job_type"
                                    v-model="form.job_type_id"
                                    name="Job Type"
                                    label="name"
                                    track-by="name"
                                    placeholder="Select category"
                                    :options="job_type_category"
                                    v-validate="'required'"
                                ></multiselect>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Job Type')">{{ errors.first('Job Type') }}</span>
                        </section>
                    </div>
                </div>
                <div class="uk-width-1-2@s">
                    <section>
                        <label for="job_description" class="uk-form-label">Qualifications<span class="mandatory">*</span></label>
                        <vue-editor
                            id="job_description"
                            name="Qualifications"
                            placeholder="Type vacancy title..." 
                            :editorToolbar="customToolbar"
                            style="background: white;"
                            v-model="form.job_description"
                            v-validate="'required'"
                        ></vue-editor>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Qualifications')">{{ errors.first('Qualifications') }}</span>
                    </section>
                </div>
                <div class="uk-width-1-2@s">
                    <div class="uk-grid-small" uk-grid>
                        <section class="uk-width-1-2@s">
                            <label for="job_start" class="uk-form-label">Start Date<span class="mandatory">*</span></label>
                            <div>
                                <div class="uk-inline uk-width-1-1">
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                    <datetime 
                                        input-id="job_start"
                                        name="Start Date"
                                        input-class="uk-input"
                                        input-style="border-radius: 5px;"
                                        type='date'
                                        placeholder="Select start date"
                                        value-zone="Asia/Jakarta"
                                        zone="Asia/Jakarta"
                                        :min-datetime="new Date().toISOString()" 
                                        v-model="form.job_start"
                                        v-validate="'required'"
                                    ></datetime>
                                </div>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Start Date')">{{ errors.first('Start Date') }}</span>
                        </section>
                        <section class="uk-width-1-2@s">
                            <label for="job_end" class="uk-form-label">End Date<span class="mandatory">*</span></label>
                            <div>
                                <div class="uk-inline uk-width-1-1">
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                    <datetime 
                                        input-id="job_end"
                                        name="End Date"
                                        input-class="uk-input"
                                        input-style="border-radius: 5px;"
                                        type='date'
                                        placeholder="Select end date"
                                        :min-datetime="datetime.fromISO(form.job_start).plus({days: 1}).toISODate()" 
                                        value-zone="Asia/Jakarta"
                                        zone="Asia/Jakarta"
                                        v-model="form.job_end"
                                        v-validate="'required'"
                                        :disabled="!form.job_start"
                                    ></datetime>
                                </div>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('End Date')">{{ errors.first('End Date') }}</span>
                        </section>
                        <section v-if="!form.salary_start_from && !form.salary_to" class="uk-width-1-2@s">
                            <label for="salary" class="uk-form-label">Salary</label>
                            <div>
                                <div class="uk-inline">
                                    <span class="uk-form-icon">Rp</span>
                                    <input
                                       
                                        id="salary"
                                        name="Salary" 
                                        class="uk-input input-text" 
                                        type="number"
                                        min="1"
                                        placeholder="Input salary..."
                                        v-model="form.salary"
                                        v-validate="'numeric|min_value:1'"
                                    >
                                </div>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Salary')">{{ errors.first('Salary') }}</span>
                        </section>
                        <section class="uk-width-1-2@s" v-if="(form.salary_start_from && form.salary_to) || (salary_range.start_from && salary_range.to)">
                            <label for="salary" class="uk-form-label">Salary range</label>
                            <div>
                                <div class="uk-inline">
                                    <span class="uk-form-icon">Rp</span>
                                    <input 
                                        type="text"
                                        class="uk-input input-text"
                                        :placeholder="form.salary_start_from + ' - ' + form.salary_to"
                                        disabled
                                    >
                                </div>
                            </div>
                        </section>
                        <section class="uk-width-1-2@s">
                            <label for="salary_unit" class="uk-form-label">Payroll</label>
                            <div>
                                <multiselect
                                    v-model="form.salary_unit"
                                    placeholder="Select category"
                                    :options="salary_unit_category"
                                    :disabled="!form.salary && !form.salary_start_from && !form.salary_to"
                                ></multiselect>
                            </div>
                        </section>
                        <section class="uk-width-1-2@s">
                            <label class="uk-form-label"><input class="uk-checkbox" type="checkbox" v-model="salary_range.use" @change.prevent="openSalaryRangeModal"> Salary range</label>
                        </section>
                        <section v-if="form.salary_start_from && form.salary_to" class="uk-width-1-2@s">
                            <button type="button" class="uk-button uk-button-primary uk-flex uk-flex-between uk-flex-middle" @click="openSalaryRangeModalEdit">
                                <span class="uk-margin-small-right uk-icon" uk-icon="file-edit"></span>
                                <span>Edit</span>
                            </button>
                        </section>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <label for="skill_needed" class="uk-form-label">Skills Required<span class="mandatory">*</span></label>
                    <vue-editor
                        id="skill_needed"
                        name="Skill Required"
                        placeholder="Type vacancy title..." 
                        :editorToolbar="customToolbar"
                        style="background: white;"
                        v-model="form.skill_needed"
                        v-validate="'required'"
                    ></vue-editor>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('Skill Required')">{{ errors.first('Skill Required') }}</span>
                </div>
                <div class="uk-width-1-1">
                    <div class="uk-grid-small" uk-grid>
                        <section class="uk-width-1-4@s">
                            <label for="education" class="uk-form-label">Education</label>
                            <multiselect
                                id="education"
                                v-model="form.education_level_id"
                                label="name"
                                track-by="name"
                                :options="education_level_id_category"
                                placeholder="Select category"
                            ></multiselect>
                        </section>
                        <section class="uk-width-1-4@s">
                            <label for="is_experience_needed" class="uk-form-label">Experience Type</label>
                            <div class="uk-margin-small-top">
                                <label class="uk-form-label">
                                    <input 
                                        id="is_experience_needed"
                                        class="uk-checkbox" 
                                        type="checkbox" 
                                        v-model="form.is_experience_needed"
                                    > 
                                    Need Experience
                                </label>
                            </div>
                        </section>
                        <div class="uk-width-1-4@s">
                            <div class="uk-grid-small" uk-grid>
                                <section class="uk-width-1-2@s">
                                    <label for="min_age" class="uk-form-label">Min Age</label>
                                    <input 
                                        id="min_age"
                                        name="Min age"
                                        class="uk-input input-text" 
                                        type="number"
                                        min="1"
                                        v-model="min_age"
                                        @input="setAgeGroup"
                                    >
                                </section>
                                <section class="uk-width-1-2@s">
                                    <label for="max_age" class="uk-form-label">Max Age</label>
                                    <input 
                                        id="max_age"
                                        name="Max age"
                                        class="uk-input input-text" 
                                        type="number"
                                        min="1"
                                        v-model="max_age"
                                        @input="setAgeGroup"
                                    >
                                </section>
                            </div>
                        </div>
                        <section class="uk-width-1-4@s">
                            <label for="gender" class="uk-form-label">Gender</label>
                            <div id="gender" class="uk-grid-small" uk-grid>
                                <label class="uk-width-1-2@s uk-form-label"><input
                                    id="male" 
                                    class="uk-checkbox" 
                                    type="checkbox"
                                    value="male" 
                                    v-model="select_gender"
                                    @change.prevent="setGender"
                                > Pria</label>
                                <label class="uk-width-1-2@s uk-form-label"><input 
                                    id="female"
                                    class="uk-checkbox" 
                                    type="checkbox"
                                    value="female" 
                                    v-model="select_gender"
                                    @change.prevent="setGender"
                                > Wanita</label>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <hr>
                </div>
                <div class="uk-width-1-1">
                    <div class="uk-grid-small" uk-grid>
                        <section class="uk-width-1-4@s">
                            <label for="company_name" class="uk-form-label">Company<span class="mandatory">*</span></label>
                            <input 
                                id="company_name"
                                name="Company" 
                                class="uk-input input-text" 
                                type="text"
                                placeholder="Type company name..."
                                v-model="form.company_name"
                                v-validate="'required'"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Company')">{{ errors.first('Company') }}</span>
                        </section>
                        <section class="uk-width-1-4@s">
                            <label for="company_website" class="uk-form-label">Company Website</label>
                            <input 
                                id="company_website"
                                name="Company Website" 
                                class="uk-input input-text" 
                                type="text"
                                placeholder="Type company name..."
                                v-model="form.company_website"
                                v-validate="{url: {require_protocol: true }}"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Company Website')">{{ errors.first('Company Website') }}</span>
                        </section>
                        <section class="uk-width-1-4@s">
                            <label for="send_to" class="uk-form-label">Send to<span class="mandatory">*</span></label>
                            <input 
                                id="send_to"
                                name="Send to" 
                                class="uk-input input-text" 
                                type="email"
                                placeholder="Type send-to..."
                                v-model="form.send_to"
                                v-validate="'required|email'"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Send to')">{{ errors.first('Send to') }}</span>
                        </section>
                        <section class="uk-width-1-4@s">
                            <label for="company_logo" class="uk-form-label">Company Logo</label><br>
                            <button class="uk-button uk-button-primary uk-border-rounded uk-flex uk-flex-middle uk-flex-center" type="button" @click="openFileCompanyLogo">
                                <span>
                                  <img :src="`${images}/cloud-download-white.svg`" alt="Cloud download icon">  
                                </span>
                                <span class="uk-margin-small-left">{{ companyLogoName }}</span>
                            </button>
                            <input
                                id="company_logo"
                                type="file"
                                ref="company_logo"
                                style="display: none"
                                @change="uploadCompanyLogo($event)"
                            >
                        </section>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <div class="uk-flex uk-flex-between uk-flex-top" style="gap: 15px;">
                        <section class="uk-width-1-2@s">
                            <label for="vacancy_poster" class="uk-form-label">Vacancy Poster</label><br>
                            <button class="uk-button uk-button-primary uk-border-rounded uk-width-1-1 uk-flex uk-flex-middle uk-flex-center" type="button" @click="openFileVacancyPoster">
                                <span>
                                    <img :src="`${images}/cloud-download-white.svg`" alt="Cloud download icon"> 
                                </span>
                                <span class="uk-margin-small-left">
                                    {{ vacancyPosterName }}
                                </span>
                            </button>
                            <input
                                id="vacancy_poster"
                                type="file"
                                ref="vacancy_poster"
                                style="display: none"
                                @change="uploadVacancyPoster($event)"
                            >
                        </section>
                        <section class="uk-width-1-1">
                            <label for="company_address" class="uk-form-label">Company Address</label>
                            <input 
                                id="company_address" 
                                class="uk-input input-text" 
                                type="text"
                                placeholder="Type company address here"
                                v-model="form.company_address"
                            >
                        </section>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <hr>
                </div>
                <div class="uk-width-1-1">
                    <div class="uk-flex uk-flex-between uk-flex-middle" style="gap: 15px;">
                        <section class="uk-width-1-1">
                            <label for="city" class="uk-form-label">Job Locations</label>
                            <multiselect
                                id="city"
                                v-model="form.city"
                                :options="job_location_category"
                                placeholder="Select category"
                            ></multiselect>
                        </section>
                        <section class="uk-width-1-1">
                            <label for="jobIndustry" class="uk-form-label">Job Industry</label>
                            <multiselect
                                id="jobIndustry"
                                v-model="form.job_industry_id"
                                label="name"
                                track-by="name"
                                :options="job_industry_category"
                                placeholder="Select category"
                            ></multiselect>
                        </section>
                        <section class="uk-width-1-1">
                            <label for="jobPreference" class="uk-form-label">Job Preference</label>
                            <multiselect
                                id="jobPreference"
                                v-model="form.job_preference_id"
                                label="name"
                                track-by="name"
                                :options="job_preference_category"
                                placeholder="Select category"
                            ></multiselect>
                        </section>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <hr>
                </div>
                <div class="uk-width-1-1">
                    <div class="uk-flex uk-flex-between uk-flex-middle">
                        <p><span class="mandatory">*</span> = required</p>
                        <button 
                            type="submit" 
                            class="uk-button uk-button-primary uk-border-rounded"
                            :disabled="buttonLoading"
                        >
                            {{ buttonLoading ? 'Loading...' : 'Save' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <!-- Salary range modal -->
        <div id="salary_range_modal" class="uk-flex-top salary_range_modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="salary_range.use = false"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input salary range</h2>
                </div>
                <div>
                    <form @submit.prevent="saveSalaryRange">
                        <div class="uk-padding">
                            <p>Please input salary range (in rupiah)</p>
                            <div class="uk-flex uk-flex-between uk-flex-middle">
                                <section>
                                    <label for="salary_start_from">Start from</label>
                                    <div>
                                        <div class="uk-inline">
                                            <span class="uk-form-icon">Rp</span>
                                            <input 
                                                id="salary_start_from"
                                                name="Salary start from"
                                                class="uk-input input-text" 
                                                type="number"
                                                min="1"
                                                v-model="salary_range.start_from"
                                                v-validate="'numeric|min_value:1'"
                                            >
                                        </div>
                                    </div>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('Salary start from')">{{ errors.first('Salary start from') }}</span>
                                </section>
                                <section>
                                    <label for="salary_to">To</label>
                                    <div>
                                        <div class="uk-inline">
                                            <span class="uk-form-icon">Rp</span>
                                            <input 
                                                id="salary_to"
                                                name="Salary to"
                                                class="uk-input input-text" 
                                                type="number"
                                                min="1"
                                                v-model="salary_range.to"
                                                v-validate="'numeric|min_value:1'"
                                            >
                                        </div>
                                    </div>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('Salary to')">{{ errors.first('Salary to') }}</span>
                                </section>
                            </div>
                        </div>

                        <section class="uk-modal-footer uk-text-right">
                            <button 
                                type="submit"
                                class="uk-button uk-button-primary"
                                :disabled="!salary_range.start_from || !salary_range.to" 
                            >Save</button>
                        </section>
                    </form>
                </div>
            </div>
        </div>
        <!-- End salary range modal -->
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { DateTime } from 'luxon';
import { Datetime } from 'vue-datetime';
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import axios from "axios";
import { PREFIX_IMAGE } from '@/utils/constant';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'CreateSilverPackage',
    components: {
        VueEditor,
        Datetime
    },
    data() {
        return {
            datetime: DateTime,
            images: PREFIX_IMAGE,
            salary_range: {
                use: false,
                start_from: null,
                to: null
            },
            select_gender: [],
            min_age: null,
            max_age: null,
            form: {
                title: '',
                job_type_id: null,
                job_description: '',
                job_start: '',
                job_end: '',
                salary: null,
                salary_start_from: null,
                salary_to: null,
                salary_unit: null,
                skill_needed: null,
                education_level_id: null,
                is_experience_needed: false,
                age_group: null,
                gender: '',
                company_name: '',
                company_website: '',
                send_to: '',
                company_logo: '',
                vacancy_poster: '',
                company_address: '',
                city: '',
                job_industry_id: null,
                job_preference_id: null
            },
            customToolbar: [
                ["bold", "italic"],
                [{ list: "bullet" }]
            ],
            job_type_category: ['Paruh Waktu', 'Purna Waktu', 'Kontrak', 'Daily Worker'],
            salary_unit_category: ['Jam', 'Hari', 'Bulan'],
            education_level_id_category: [],
            job_location_category: [],
            job_industry_category: [],
            job_preference_category: [],
            companyLogoName: 'Upload Image',
            vacancyPosterName: 'Upload Image',
            buttonLoading: false
        };
    },
    mounted() {
        this.getJobTypeList();
        this.getCompanyIndustry();
        this.dataCity();
        this.getJobPreference();
        this.getEducationList();
    },
    beforeMount() {
        if (window.UIkit.modal('.salary_range_modal')) window.UIkit.modal('.salary_range_modal').$destroy(true);
    },
    computed: {
        user_cred() {
            return getUserLogin();
        }
    },
    methods: {
        ...mapActions({
            uploadImage: 'file/uploadImage',
            getJobType: 'job/getJobType',
            getCompanyIndustries: 'company/getCompanyIndustries',
            getJobPreferenceLists: 'job_preference/getJobPreferenceLists',
            getEducation: 'option_data/getEducation',
            createSilverPackage: 'job/createSilverPackage'
        }),
        getJobTypeList() {
            this.getJobType()
                .then((response) => {
                    this.job_type_category = response;
                });
        },
        getCompanyIndustry() {
            this.getCompanyIndustries()
                .then((response) => {
                    this.job_industry_category = response.docs;
                });
        },
        getJobPreference() {
            this.getJobPreferenceLists()
                .then((response) => {
                    this.job_preference_category = response.docs;
                });
        },
        getEducationList() {
            this.getEducation()
                .then((response) => {
                    this.education_level_id_category = response;
                });
        },
        openFileCompanyLogo() {
            this.$refs.company_logo.click();
        },
        async uploadCompanyLogo() {
            this.companyLogoName = event.target.files[0].name;
            let files = this.$refs.company_logo.files;
            let formData = new FormData();

            for( var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImage({ folder: 'silver-package-image', category: 'company-logo', data: formData });
                this.form.company_logo = result.file_path;
            }
        },
        openFileVacancyPoster() {
            this.$refs.vacancy_poster.click();
        },
        async uploadVacancyPoster() {
            this.vacancyPosterName = event.target.files[0].name;
            let files = this.$refs.vacancy_poster.files;
            let formData = new FormData();

            for( var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImage({ folder: 'silver-package-image', category: 'vacancy-poster', data: formData });
                this.form.vacancy_poster = result.file_path;
            }
        },
        async openSalaryRangeModal() {
            if(this.salary_range.use) {
                await window.UIkit.modal("#salary_range_modal").show();
            } else {
                this.salary_range.use = false;
                this.salary_range.start_from = null;
                this.salary_range.to = null;
                this.form.salary_start_from = null;
                this.form.salary_to = null;
                this.form.salary_unit = null;
                await window.UIkit.modal("#salary_range_modal").hide();
            }
        },
        async openSalaryRangeModalEdit() {
            await window.UIkit.modal("#salary_range_modal").show();
        },
        async saveSalaryRange() {
            this.form.salary_start_from = this.salary_range.start_from;
            this.form.salary_to = this.salary_range.to;
            this.form.salary = null;
            await window.UIkit.modal("#salary_range_modal").hide();
        },
        setGender() { 
            const string = this.select_gender.sort().toString();
            const stringReplace = string.replace(',', ' / ');
            this.form.gender = stringReplace;
        },
        setAgeGroup() {
            if(this.min_age !== null && this.max_age === null) {
                this.form.age_group = `${this.min_age}`;
            } else if(this.max_age !== null && this.min_age === null) {
                this.form.age_group = `${this.max_age}`;
            } else {
                this.form.age_group = `${this.min_age} - ${this.max_age}`;
            }
        },
        dataCity() {
            axios.get('/static/kota-kabupaten.json').then(res => {
                this.job_location_category = res.data;
            }).catch((error) => {
                notificationDanger(error);
            });
        },
        resetForm() {
            this.salary_range = {
                use: false,
                start_from: null,
                to: null
            };
            this.select_gender = [];
            this.min_age = null;
            this.max_age = null;
            this.form = {
                title: '',
                job_type_id: null,
                job_description: '',
                job_start: '',
                job_end: '',
                salary: null,
                salary_start_from: null,
                salary_to: null,
                salary_unit: null,
                skill_needed: null,
                education_level_id: null,
                is_experience_needed: false,
                age_group: null,
                gender: '',
                company_name: '',
                company_website: '',
                send_to: '',
                company_logo: '',
                vacancy_poster: '',
                company_address: '',
                city: '',
                job_industry_id: null,
                job_preference_id: null
            };
        },
        async createSilver() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.buttonLoading = true;
                
                this.form.company_logo ? null : delete this.form.company_logo;
                this.form.vacancy_poster ? null : delete this.form.vacancy_poster;

                const payload = {
                    title: this.form.title,
                    job_type_id: this.form.job_type_id ? this.form.job_type_id._id : null,
                    job_description: this.form.job_description,
                    job_start: this.form.job_start,
                    job_end: this.form.job_end,
                    salary: this.form.salary !== null ? this.form.salary : 0,
                    salary_unit: this.form.salary_unit,
                    salary_min: this.form.salary_start_from !== null ? this.form.salary_start_from : 0,
                    salary_max: this.form.salary_to !== null ? this.form.salary_to : 0,
                    skill_needed: this.form.skill_needed,
                    education_level_id: this.form.education_level_id ? this.form.education_level_id._id : null,
                    is_experience_needed: this.form.is_experience_needed,
                    age_group: this.form.age_group,
                    gender: this.form.gender,
                    company_name: this.form.company_name,
                    company_website: this.form.company_website,
                    send_to: this.form.send_to,
                    company_logo: this.form.company_logo,
                    poster: this.form.vacancy_poster,
                    company_address: this.form.company_address,
                    city: this.form.city,
                    job_industry_id: this.form.job_industry_id ? this.form.job_industry_id._id : null,
                    job_preference_id: this.form.job_preference_id ? this.form.job_preference_id._id : null
                };

                const response = await this.createSilverPackage(payload);
                if (response.result === 'success') {
                    notificationSuccess('Job successfully created');
                    this.resetForm();
                    this.buttonLoading = false;
                    this.$router.push('/admin/marketing/silver_package');
                } 
            } catch (error) {
                this.buttonLoading = false;
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    } 
};
</script>

<style scoped>
.input-text {
    border-radius: 5px;
}

.mandatory {
    color: red;
    font-weight: bold;
}

.background-white {
    background: white;
}
</style>
